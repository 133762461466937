import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import LazyLoad from "react-lazyload"
import SEO from "../components/seo"

const LineupPage = ({ data }) => {
  const allFacilitators = data.allDatoCmsFacilitator.nodes
  const sortedFacilitators = [...allFacilitators].sort((a, b) => {
    if (!a.priority) return 1
    if (!b.priority) return -1
    return a.priority - b.priority
  })
  const { heading, subheading } = data.datoCmsLineupPage

  return (
    <Layout fillMenu>
      <SEO title={heading} />

      <div class="container mt-5 is-small is-text-center">
        <h1 class="heading mb-3">{heading}</h1>
        <p class="subheading">{subheading}</p>
      </div>

      <div class="container is-padded">
        <div class="facilitators">
          {sortedFacilitators.map((facilitator, index) => (
            <LazyLoad offset={100}>
              <Link
                to={facilitator.url}
                class={`facilitator is-${facilitator.columnSize}`}
                style={{
                  backgroundImage: `url(${facilitator.mainImage.url}?w=1200&q=27&auto=compress)`,
                }}
              >
                <h1 class="h1">{facilitator.heading}</h1>
              </Link>
            </LazyLoad>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default LineupPage

export const query = graphql`
  query Facilitators {
    datoCmsLineupPage {
      heading
      subheading
      mainImage {
        url
      }
    }
    allDatoCmsFacilitator {
      nodes {
        url
        heading
        mainImage {
          url
        }
        priority
        columnSize
      }
    }
  }
`
